/*
 * @Author: Nick juntong.wu@travel-easy.com
 * @Date: 2024-08-22 16:19:52
 * @LastEditors: Nick juntong.wu@travel-easy.com
 * @LastEditTime: 2024-08-29 21:26:17
 * @FilePath: \settlement-frontend\src\core\directive\type\kyc.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

/**
 * 结算主体类型，主体类型
 */
export enum KycType {
  Juridical = "juridical",
  Natural = "natural",
}

/**
 * 卡片组件类型枚举，用于前端交互传参
 */
export enum CardComponentsType {
  Contact = "Contact",
  Registration = "Registration",
  Billing = "Billing",
  Warehouse = "Warehouse",
  Inventory = "Inventory",
  Financial = "financial",
  Tax = "Tax",
}

/**
 * 应用场景，比如验证结算主体详情，确认结算主体的详情，已确认结算主体的详情，已通过验证，但是未激活数据的详情。
 */
export enum ApplicationScenarios {
  Validations = "validations",
  Confirmations = "confirmations",
  Production = "production",
  Historical = "historical",
}

/**
 * 生命周期状态，表示数据在生命周期中的某个阶段或位置，侧重于表示数据的整体状态
 */
export enum KycState {
  Production = "production",
  Raw = "raw",
  Transformed = "transformed",
}

/**
 * 地址状态，后端翻译文字直接输出显示, 表示数据在当前阶段下的具体情况
 */
export enum KycStatus {
  Draft = "5",
  Activated = "10",
  Deactivated = "25",
  Locked = "30",
  SubmitToApproval = "40",
  Disapproved = "50",
  Archived = "100",
}

/**
 * KYC展示状态
 */
export enum KycDisplayStatus {
  Registered = "5",
  Validated = "10",
  Rejected = "20",
  Expired = "40",
}

/**
 * 主体状态，表示主体的当前状态，比如激活，未激活等。
 */
export enum entityStatus {
  Activated = 10,
  Deactivated = 25,
  Unverified = 50,
}

/**
 * 交易币种，交易币种
 */
export enum Currency {
  Eur = "EUR",
  Cny = "CNY",
  Hkd = "HKD",
}

/**
 * 支付方式，支付方式.
 * Tagging Key: settlement_payment_method
 */
export enum PaymentMethod {
  Alipay = "alipay",
  BankTransfer = "bank_transfer",
  CreditCard = "credit_card",
  Paypal = "paypal",
  Wechat = "wechat",
  YabandPay = "yaband_pay",
}

/**
 * 支付模式，支付模式.
 * Tagging Key: : settlement_payment_mode
 */
export enum PaymentMode {
  Omni = "omni",
  Sf = "sf",
  Ttp = "ttp",
}

/**
 * 税务申报频率，每年: annually,每季: quarterly,每月: monthly
 */
export enum TaxDeclarationFrequency {
  Annually = "annually",
  Monthly = "monthly",
  Quarterly = "quarterly",
}

/**
 * TAX类型，Tax 类型, 可以多选，值为: vat,import_duty
 */
export enum TaxType {
  ImportDuty = "import_duty",
  Vat = "vat",
}

export enum EntityServiceStatus {
  PurchaseItems = 1,
  SalesItems,
  LuxuryFulfillment,
  TaxVatSettlement,
  Compliance,
}
