/*
 * @Descripttion:
 * @Author: xiang.gao 1543865938
 * @Date: 2022-06-09 16:36:06
 * @LastEditors: Nick juntong.wu@travel-easy.com
 * @LastEditTime: 2024-08-27 23:39:39
 */
import { ComputedRef, computed } from "vue";
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { Modal } from "bootstrap";
import moment from "moment";
import {
  CommonDateType,
  CommonLanguageType,
  NumberOrString,
  ProjetcsAll,
} from "../type/common";
import { CommonModuleBCN, CommonModuleRouter } from "../interface/common";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import {
  getSettlementDocumentsDetailModule,
  getSettlementDocumentsModule,
} from "./settlementDocuments";
import {
  getQuickSettlementDetailModule,
  getQuickSettlementModule,
} from "./quickSettlement";
import fileDownload from "js-file-download";
import momentTimezone from "moment-timezone";
import {
  getMerchantInvoiceDetailModule,
  getMerchantInvoiceModule,
} from "./merchantInvoice";
import {
  getDocumentCenterDetailModule,
  getDocumentCenterModule,
} from "./documentCenter";
import config from "@/core/config/ApiConfig";
import {
  getApiIntegrationDetailModule,
  getApiIntegrationModule,
} from "./apiIntegration";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  getMerchantCreditNotesDetailModule,
  getMerchantCreditNotesModule,
} from "./merchantCreditNotes";
import {
  getTransactionReportDetailModule,
  getTransactionReportModule,
} from "./transactionReport";
import {
  getSettlementCashDetailModule,
  getSettlementCashModule,
} from "./settlementCash";
import {
  getSettlementMasterDataDetailModule,
  getSettlementMasterDataModule,
} from "./settlementMasterData";
import accounting from "accounting-js";
import {
  getEntityRelationshipsDetailModule,
  getEntityRelationshipsModule,
} from "./entityRelationships";
import {
  getKycVerificationDetailModule,
  getKycVerificationModule,
  getKycConfirmationDetailModule,
  getKycConfirmationModule,
  getSettlementEntityDetailModule,
  getSettlementEntityModule,
} from "./kyc";
import {
  getServiceManagementDetailModule,
  getServiceManagementModule,
} from "./serviceManagement";
import {
  getRefundProcessingDetailModule,
  getRefundProcessingModule,
} from "./refundProcessing";

/**
 * @description: 获取列表
 * @return CommonModuleRouter
 */
export const getModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  if (route.path.includes("/document-center/")) {
    return getDocumentCenterModule(language, route);
  } else if (route.path.includes("/documents/")) {
    return getSettlementDocumentsModule(language, route);
  } else if (route.path.indexOf("/api-integration") > -1) {
    return getApiIntegrationModule(language, route);
  } else if (route.path.indexOf("/quick-settlement") > -1) {
    return getQuickSettlementModule(language, route);
  } else if (route.path.includes("/merchant-invoice")) {
    return getMerchantInvoiceModule(language, route);
  } else if (route.path.includes("/merchant-credit-notes")) {
    return getMerchantCreditNotesModule(language, route);
  } else if (route.path.includes("/transaction-report")) {
    return getTransactionReportModule(language, route);
  } else if (route.path.includes("/settlement-cash")) {
    return getSettlementCashModule(language, route);
  } else if (route.path.includes("/settlement-master-data")) {
    return getSettlementMasterDataModule(language, route);
  } else if (route.path.includes("/entity-relationships")) {
    return getEntityRelationshipsModule(language, route);
  } else if (route.path.includes("/settlement-entity")) {
    return getSettlementEntityModule(language, route);
  } else if (route.path.includes("/service-management")) {
    return getServiceManagementModule(language, route);
  } else if (route.path.includes("/kyc-verification")) {
    return getKycVerificationModule(language, route);
  } else if (route.path.includes("/kyc-confirmation")) {
    return getKycConfirmationModule(language, route);
  } else if (route.path.includes("/refund-processing")) {
    return getRefundProcessingModule(language, route);
  }
  return getSettlementDocumentsModule(language, route);
};

/**
 * @description: 获取详情
 * @return CommonModuleRouter
 */
export const getDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  if (route.path.includes("/document-center/")) {
    return getDocumentCenterDetailModule(language, route, router);
  } else if (route.path.includes("/documents/")) {
    return getSettlementDocumentsDetailModule(language, route, router);
  } else if (route.path.indexOf("/api-integration") > -1) {
    return getApiIntegrationDetailModule(language, route, router);
  } else if (route.path.indexOf("/quick-settlement") > -1) {
    return getQuickSettlementDetailModule(language, route, router);
  } else if (route.path.includes("/merchant-invoice")) {
    return getMerchantInvoiceDetailModule(language, route, router);
  } else if (route.path.includes("/merchant-credit-notes")) {
    return getMerchantCreditNotesDetailModule(language, route, router);
  } else if (route.path.includes("/transaction-report")) {
    return getTransactionReportDetailModule(language, route, router);
  } else if (route.path.includes("/settlement-cash")) {
    return getSettlementCashDetailModule(language, route, router);
  } else if (route.path.includes("/settlement-master-data")) {
    return getSettlementMasterDataDetailModule(language, route, router);
  } else if (route.path.includes("/entity-relationships")) {
    return getEntityRelationshipsDetailModule(language, route, router);
  } else if (route.path.includes("/settlement-entity")) {
    return getSettlementEntityDetailModule(language, route, router);
  } else if (route.path.includes("/service-management")) {
    return getServiceManagementDetailModule(language, route, router);
  } else if (route.path.includes("/kyc-verification")) {
    return getKycVerificationDetailModule(language, route, router);
  } else if (route.path.includes("/kyc-confirmation")) {
    return getKycConfirmationDetailModule(language, route, router);
  } else if (route.path.includes("/refund-processing")) {
    return getRefundProcessingDetailModule(language, route, router);
  }
  return getSettlementDocumentsDetailModule(language, route, router);
};

/**
 * @description: 设置BCN
 * @return CommonModuleRouter
 */
export const setModuleBCN = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router?: Router
): void => {
  if (router) {
    const moudleResult = getDetailModule(language, route, router);
    const arr = setParentBCN(moudleResult);
    setCurrentPageBreadcrumbs(moudleResult.currentName, [
      ...arr,
      {
        name: moudleResult.listName,
        path: moudleResult.listPath,
      },
    ]);
  } else {
    const moudleResult = getModule(language, route);
    const arr = setParentBCN(moudleResult);
    setCurrentPageBreadcrumbs(moudleResult.currentName, arr);
  }
};

const setParentBCN = (module: CommonModuleRouter) => {
  const arr: CommonModuleBCN[] = [];
  module.parentName.map((item) => {
    arr.push({
      name: item,
      path: "",
    });
  });
  return arr;
};

/**
 * @description: 处理文件size
 * @param {number} size
 * @return {*}
 */
export const substringFileName = (fileName: string, type = 0): string => {
  if (type === 0) {
    return fileName.substring(0, fileName.lastIndexOf("."));
  }
  return fileName.substring(fileName.lastIndexOf("."));
};

/**
 * @description: 处理文件size
 * @param {number} size
 * @return {*}
 */
export const bytesToSize = (fileSize: number): string => {
  let temp: string | number = fileSize;
  if (temp < 1024) {
    return temp + "B";
  } else if (fileSize < 1024 * 1024) {
    temp = fileSize / 1024;
    return temp.toFixed(2) + "KB";
  } else if (fileSize < 1024 * 1024 * 1024) {
    temp = fileSize / (1024 * 1024);
    return temp.toFixed(2) + "MB";
  } else {
    temp = fileSize / (1024 * 1024 * 1024);
    return temp.toFixed(2) + "GB";
  }
};

/**
 * @description: 返回上一页（没有上一页时返回指定页面）
 * @param {Router} router
 * @param {string} path
 * @return {*}
 */
export const commonBackToList = (router: Router, path: string): void => {
  if (window.history.state.back) {
    router.back();
  } else {
    router.replace(path);
  }
};

/**
 * @description: 跳转项目页面（指定项目页面）
 * @param {Router} router
 * @param {string} path
 * @return {*}
 */
export const commonGoToPath = (path: string, type?: ProjetcsAll): string => {
  let url = "";
  const urlString = "/#";
  switch (type) {
    case "Merchant":
      url = config.merchantUrl + urlString + path;
      break;
    case "Distribution":
      url = config.distributionUrl + urlString + path;
      break;
    case "Fulfillment":
      url = config.fulfillmentUrl + urlString + path;
      break;
    case "Settlement":
      url = config.settlementUrl + urlString + path;
      break;
    case "PIM":
      url = config.pimUrl + urlString + path;
      break;
    case "CDN":
      url = config.cdnUrl + path;
      break;
    default:
      url = config.baseUrl + urlString + path;
  }
  return url;
};

export const modalShowListener = (
  modalEl: HTMLElement | null,
  callback
): void => {
  if (!modalEl) {
    return;
  }
  modalEl.addEventListener("show.bs.modal", callback);
};

/**
 * @description: 公共下载文件流方法
 * @param {*} data
 * @return {*}
 */
export const commonExportFile = (data, name?) => {
  const fileReader = new FileReader();
  fileReader.onload = function () {
    try {
      // 说明是普通对象数据，后台转换失败
      const jsonData = JSON.parse(this.result as string);
      showServerErrorMsg(jsonData);
    } catch (err) {
      // 解析成对象失败，说明是正常的文件流
      const disposition = data.headers["content-disposition"];
      if (!name) {
        const fileNameString = disposition.split("filename=")[1];
        let fileName = fileNameString.replaceAll('"', "").replaceAll("'", "");
        if (fileName.indexOf("filename*=utf-8") > -1) {
          fileName = decodeURI(fileName.split("filename*=utf-8")[1]);
        }
        fileName = decodeURIComponent(fileName);
        fileDownload(data.data, decodeURI(fileName));
      } else {
        const fileName = decodeURIComponent(name);
        fileDownload(data.data, decodeURI(fileName));
      }
    }
  };
  fileReader.readAsText(data.data);
};

/**
 * 显示服务端错误消息弹框
 */
const showServerErrorMsg = (res) => {
  const errorMsg = res.data;
  let htmlMsg = "";
  if (errorMsg) {
    for (const key in errorMsg) {
      htmlMsg += errorMsg[key][0] + "<br/>";
    }
  }
  if (res.sub_code.indexOf("FORM-VALIDATION") != -1) {
    //服务端数据验证错误
    Swal.fire({
      title: res.sub_msg,
      icon: "error",
      html: htmlMsg,
    });
  } else {
    //服务端其他错误
    Swal.fire({
      title: res.msg,
      text: res.sub_msg,
      icon: "error",
    });
  }
};

/**
 * @description: 公共查看文件流方法
 * @param {*} data
 * @return {*}
 */
export const commonPreviewFile = (childWin, name, data) => {
  try {
    let fileURL;
    // var blob = new Blob([data], { type: "application/pdf" });
    if (window.webkitURL != undefined) {
      // webkit or chrome
      fileURL = window.webkitURL.createObjectURL(data);
    } else if (window.URL != undefined) {
      // mozilla(firefox)
      fileURL = window.URL.createObjectURL(data);
    }
    childWin = window.open(
      fileURL,
      name,
      "height=700, width=600, top=10, left=10, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
    ) as Window;
    childWin.onload = function () {
      // childWin.print();
      if (window.webkitURL != undefined) {
        // webkit or chrome
        window.webkitURL.revokeObjectURL(fileURL);
      } else if (window.URL != undefined) {
        // mozilla(firefox)
        window.URL.revokeObjectURL(fileURL);
      }
    };
  } catch (error) {
    // console.log(error);
  }
};

/**
 * @description: 公共查看文件流方法
 * @param {*} data
 * @return {*}
 */
export const commonPreviewHtml = (childWin, name, data) => {
  try {
    let fileURL;
    const blob = new Blob([data], { type: "text/html" });
    if (window.webkitURL != undefined) {
      // webkit or chrome
      fileURL = window.webkitURL.createObjectURL(blob);
    } else if (window.URL != undefined) {
      // mozilla(firefox)
      fileURL = window.URL.createObjectURL(blob);
    }
    childWin = window.open(
      fileURL,
      name,
      "height=842, width=595, top=10, left=10, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
    ) as Window;
    childWin.onload = function () {
      // childWin.print();
      if (window.webkitURL != undefined) {
        // webkit or chrome
        window.webkitURL.revokeObjectURL(fileURL);
      } else if (window.URL != undefined) {
        // mozilla(firefox)
        window.URL.revokeObjectURL(fileURL);
      }
    };
  } catch (error) {
    // console.log(error);
  }
};

export const commonChangeFilterAddDate = (date: string, day = 0): string => {
  return moment
    .tz(moment(date).add(day, "days").utc(true).toISOString(), "Europe/Berlin")
    .startOf("days")
    .toISOString();
};

export const commonDefaultDate = (date): string => {
  return moment(date).add(12, "hours").toISOString();
};

export const commonDefaultAddDate = (date, day: number): string => {
  return moment(date).add(12, "hours").add(day, "days").toISOString();
};

export const settlementAddDate = (
  time,
  value = 0,
  mode: moment.unitOfTime.DurationConstructor = "days"
) => {
  return moment(time).add(value, mode).format("DD.MM.YYYY");
};

export const settlementLastDay = ({
  time = moment(),
  value = -1,
  isStart = true,
}) => {
  if (isStart) {
    return moment(time).tz("Europe/Berlin").add(value, "days").startOf("day");
  } else {
    return moment(time).tz("Europe/Berlin").add(value, "days").endOf("day");
  }
};

export const settlementLastDayDate = ({
  time = moment(),
  value = -1,
  isStart = true,
  format = "DD.MM.YYYY",
}) => {
  return settlementLastDay({ time, value, isStart }).format(format);
};

export const settlementLastDayDateString = ({
  time = moment(),
  value = -1,
  isStart = true,
}) => {
  return settlementLastDay({ time, value, isStart }).toISOString();
};

export const settlementLastWeek = ({
  time = moment(),
  value = -1,
  isStart = true,
}) => {
  if (isStart) {
    return moment(time)
      .tz("Europe/Berlin")
      .add(value, "weeks")
      .startOf("week")
      .add("1", "days");
  } else {
    return moment(time)
      .tz("Europe/Berlin")
      .add(value, "weeks")
      .endOf("week")
      .add("1", "days");
  }
};

export const settlementLastWeekDate = ({
  time = moment(),
  value = -1,
  isStart = true,
  format = "DD.MM.YYYY",
}) => {
  return settlementLastWeek({ time, value, isStart }).format(format);
};

export const settlementLastWeekDateString = ({
  time = moment(),
  value = -1,
  isStart = true,
}) => {
  return settlementLastWeek({ time, value, isStart }).toISOString();
};

export const settlementLastMonth = ({
  time = moment(),
  value = -1,
  isStart = true,
}) => {
  if (isStart) {
    return moment(time)
      .tz("Europe/Berlin")
      .add(value, "months")
      .startOf("month");
  } else {
    return moment(time).tz("Europe/Berlin").add(value, "months").endOf("month");
  }
};

export const settlementLastMonthDate = ({
  time = moment(),
  value = -1,
  isStart = true,
  format = "DD.MM.YYYY",
}) => {
  return settlementLastMonth({ time, value, isStart }).format(format);
};

export const settlementLastMonthDateString = ({
  time = moment(),
  value = -1,
  isStart = true,
}) => {
  return settlementLastMonth({ time, value, isStart }).toISOString();
};

export const settlementLastQuarter = ({
  time = moment(),
  value = 0,
  isStart = true,
}) => {
  const currentQuarter = Math.floor((time.month() + 1) / 3);
  let targetQuarter = currentQuarter + value + 1;
  targetQuarter = ((targetQuarter % 4) + 4) % 4;
  const targetMonth = targetQuarter * 3 - 2;

  if (isStart) {
    return time.tz("Europe/Berlin").month(targetMonth).startOf("quarter");
  } else {
    return time.tz("Europe/Berlin").month(targetMonth).endOf("quarter");
  }
};

export const settlementLastQuarterDate = ({
  time = moment(),
  value = 0,
  isStart = true,
  format = "DD.MM.YYYY",
}) => {
  return settlementLastQuarter({ time, value, isStart }).format(format);
};

export const settlementLastQuarterDateString = ({
  time = moment(),
  value = 0,
  isStart = true,
}) => {
  return settlementLastQuarter({ time, value, isStart }).toISOString();
};

export const settlementLastYear = ({
  time = moment(),
  value = -1,
  isStart = true,
}) => {
  if (isStart) {
    return moment(time).tz("Europe/Berlin").add(value, "years").startOf("year");
  } else {
    return moment(time).tz("Europe/Berlin").add(value, "years").endOf("year");
  }
};

export const settlementLastYearDate = ({
  time = moment(),
  value = -1,
  isStart = true,
  format = "DD.MM.YYYY",
}) => {
  return settlementLastYear({ time, value, isStart }).format(format);
};

export const settlementLastYearDateString = ({
  time = moment(),
  value = -1,
  isStart = true,
}) => {
  return settlementLastYear({ time, value, isStart }).toISOString();
};

export const commonChangeDefaultDate = (
  e,
  form,
  data: string,
  type?: string
): void => {
  const value = moment
    .tz(moment(e).utc(true).toISOString(), "Europe/Berlin")
    .startOf("days")
    .toISOString();
  if (type) {
    form[data][type] = value;
  } else {
    form[data] = value;
  }
};

export const commonChangeDefaultDateTime = (
  e,
  form,
  data: string,
  type?: string
): void => {
  const value = moment(e, "DD.MM.YYYY HH:mm:ss").add(12, "hours").toISOString();
  if (type) {
    form[data][type] = value;
  } else {
    form[data] = value;
  }
};

export const commonDefaultMonth = (e) => {
  const value = moment
    .tz(moment(e).utc(true).toISOString(), "Europe/Berlin")
    .startOf("month")
    .toISOString();
  return value;
};

export const CommonArrayToString = (value: Array<number | string>) => {
  return value.toString().replaceAll(",", ", ");
};

export const formatDate = (date: CommonDateType, format = "DD.MM.YYYY") => {
  // return date ? moment(date).utcOffset(2).format(format) : "";
  // const localLocale = momentTimezone.tz.zonesForCountry("DE"); // 获取德国地区时区名字
  return date ? momentTimezone(date).tz("Europe/Berlin").format(format) : "";
};

export const formatDateTime = (
  date: CommonDateType,
  format = "DD.MM.YYYY HH:mm:ss"
) => {
  // return date ? moment(date).utcOffset(2).format(format) : "";
  // const localLocale = momentTimezone.tz.zonesForCountry("DE"); // 获取德国地区时区名字
  return date ? momentTimezone(date).tz("Europe/Berlin").format(format) : "";
};

export const formatUtcDate = (date: CommonDateType, format = "DD.MM.YYYY") => {
  return date ? moment(date).format(format) : "";
};

export const formatUtcRangeDate = (
  rangeDate: CommonDateType,
  format = "DD.MM.YYYY"
) => {
  if (rangeDate) {
    const range = rangeDate.split(" - ");
    return (
      formatUtcDate(range[0], format) + " - " + formatUtcDate(range[1], format)
    );
  }
  return "";
};

/**
 * @description: 检查手机号
 * @param {string} mobile
 * @return boolean
 */
export const testMobile = (mobile: string): boolean => {
  const mobileRegexp = /^1[23456789]\d{9}$/;
  return mobileRegexp.test(mobile);
};

/**
 * @description: 检查身份证号
 * @param {string} IDCard
 * @return boolean
 */
export const testIDCard = (IDCard: string): boolean => {
  const idCardRegexp =
    /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  return idCardRegexp.test(IDCard);
};

/**
 * @description: 只能输入数字和英文字母
 * @param {string} value
 * @return string
 */
export const filterSpecialSymbolsChineses = (value: string): string => {
  return value.replace(/[\u4e00-\u9fa5/\s+/]|[^a-zA-Z0-9\u4E00-\u9FA5]/g, "");
};

/**
 * @description: 过滤特殊符号
 * @param {string} value
 * @return string
 */
export const filterSpecialSymbols = (value: string): string => {
  return value.replace(/[^a-zA-Z0-9\u4E00-\u9FA5]/g, "");
};

/**
 * @description: 过滤中文
 * @param {string} value
 * @return string
 */
export const filterChineses = (value: string): string => {
  return value.replace(/[\u4e00-\u9fa5/\s+/]/g, "");
};

/**
 * @description:
 * @param {HTMLElement} modalEl
 * @param {*} callback
 * @return {*}
 */
export const modalKeyDownListener = (
  modalEl: HTMLElement | null,
  callback
): void => {
  if (!modalEl) {
    return;
  }
  modalEl.addEventListener("keydown", callback);
};

/**
 * @description:
 * @return {*}
 */
export const modalRemoveKeyDownListener = (
  modalEl: HTMLElement | null,
  callback
): void => {
  if (!modalEl) {
    return;
  }
  modalEl.removeEventListener("keydown", callback);
};

export const showModal = (modalEl: HTMLElement | null): void => {
  if (!modalEl) {
    return;
  }
  const myModal = new Modal(modalEl);
  myModal.show();
};

export const getCurrency = computed(() => {
  return (currencyOne, currencyTwo) => {
    if (currencyOne) {
      return currencyOne;
    }
    return currencyTwo;
  };
});

export const currencyCount = (
  val: NumberOrString,
  precision: number,
  decimal: string,
  thousand: string,
  symbol: string
) => {
  return accounting.formatMoney(val, {
    symbol: symbol,
    precision: precision,
    thousand: thousand,
    decimal: decimal,
  });
};

export const useComputedFn = <T, U extends unknown[]>(
  fn: (...args: U) => T
): ((...args: U) => ComputedRef<T>) => {
  const cache: Map<string, ComputedRef<T>> = new Map();
  return (...args: U): ComputedRef<T> => {
    const cacheKey = JSON.stringify(args);
    if (cache.has(cacheKey)) return cache.get(cacheKey) as ComputedRef<T>;
    const result = computed(() => fn(...args));
    cache.set(cacheKey, result);
    return result;
  };
};

export const useVmodel = (props, propName, emit) => {
  return computed({
    get() {
      return new Proxy(props[propName], {
        get(target, key) {
          return Reflect.get(target, key);
        },
        set(target, key, value) {
          emit("update:" + propName, {
            ...target,
            [key]: value,
          });
          return true;
        },
      });
    },
    set(value) {
      emit("update:" + propName, value);
    },
  });
};
