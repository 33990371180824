/*
 * @Author: Nick juntong.wu@travel-easy.com
 * @Date: 2024-08-23 19:44:31
 * @LastEditors: Nick juntong.wu@travel-easy.com
 * @LastEditTime: 2024-08-29 21:27:23
 * @FilePath: \settlement-frontend\src\core\directive\function\kyc.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { RouteLocationNormalizedLoaded, Router, useRoute } from "vue-router";
import { BadgeStyle, CommonLanguageType, NumberOrString } from "../type/common";

import { CommonModuleRouter } from "../interface/common";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import {
  KycCommonConfig,
  KycConfirmationConfig,
  KycVerificationConfig,
  SettlementEntityConfig,
} from "../interface/kyc";
import {
  KycStatus,
  KycDisplayStatus,
  EntityServiceStatus,
  KycType,
  ApplicationScenarios,
  entityStatus,
} from "../type/kyc";
import { ApiKyc } from "@/core/api";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getKycVerificationModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setKycVerificationDetailName(language, route, result);
  return result;
};
export const getKycConfirmationModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setKycConfirmationDetailName(language, route, result);
  return result;
};
export const getSettlementEntityModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setSettlementEntityDetailName(language, route, result);
  return result;
};

/**
 * @description: 获取外层path和name
 * @return CommonModuleRouter
 */
const setKycVerificationDetailName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/kyc-verification/";
  result.parentName = [language("common.kycVerification")];
  if (route.path.includes("legal-entity")) {
    strName = language("kyc.legalEntity.title");
    listPath += "legal-entity";
  } else if (route.path.includes("natural-person")) {
    strName = language("kyc.naturalPerson.title");
    listPath += "natural-person";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};
const setKycConfirmationDetailName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/kyc-confirmation/";
  result.parentName = [language("common.kycConfirmation")];
  if (route.path.includes("legal-entity")) {
    strName = language("kyc.legalEntity.title");
    listPath += "legal-entity";
  } else if (route.path.includes("natural-person")) {
    strName = language("kyc.naturalPerson.title");
    listPath += "natural-person";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};
const setSettlementEntityDetailName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/settlement-entity/";
  result.parentName = [language("settlementEntity.settlementEntity")];
  if (route.path.includes("legal-entity")) {
    strName = language("kyc.legalEntity.title");
    listPath += "legal-entity";
  } else if (route.path.includes("natural-person")) {
    strName = language("kyc.naturalPerson.title");
    listPath += "natural-person";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取内层path和name
 * @return CommonModuleRouter
 */
export const getKycVerificationDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "kyc-verification-legal-entity-information":
    case "kyc-verification-legal-entity-history-logs":
      result.currentName = language("kyc.legalEntity.overviewTitle");
      setKycVerificationDetailName(language, route, result, router);
      break;
    case "kyc-verification-natural-person-information":
    case "kyc-verification-natural-person-history-logs":
      result.currentName = language("kyc.naturalPerson.overviewTitle");
      setKycVerificationDetailName(language, route, result, router);
      break;
    case "kyc-verification-legal-entity-add":
      result.currentName = language("kyc.legalEntity.addTitle");
      setKycVerificationDetailName(language, route, result, router);
      break;
    case "kyc-verification-natural-person-add":
      result.currentName = language("kyc.naturalPerson.addTitle");
      setKycVerificationDetailName(language, route, result, router);
      break;
  }
  return result;
};
export const getKycConfirmationDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "kyc-confirmation-legal-entity-information":
      result.currentName = language("kyc.legalEntity.overviewTitle");
      setKycConfirmationDetailName(language, route, result, router);
      break;
    case "kyc-confirmation-natural-person-information":
      result.currentName = language("kyc.naturalPerson.overviewTitle");
      setKycConfirmationDetailName(language, route, result, router);
      break;
  }
  return result;
};
export const getSettlementEntityDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "settlement-entity-legal-entity-information":
    case "settlement-entity-natural-person-information":
      result.currentName = language("kyc.navigation.information");
      setSettlementEntityDetailName(language, route, result, router);
      break;
    case "settlement-entity-legal-entity-historical-information":
    case "settlement-entity-natural-person-historical-information":
      result.currentName = language("kyc.navigation.historicalInformation");
      setSettlementEntityDetailName(language, route, result, router);
      break;
  }
  return result;
};

/**
 * @description: KYC状态badge
 * @param {NumberOrString} status
 * @param {CommonLanguageType} language
 * @return {*}
 */
export const getKycStatus = (status: NumberOrString) => {
  let color = "";
  const currentStatus = status as KycStatus;
  switch (currentStatus) {
    case KycStatus.Draft:
      color = BadgeStyle.Light;
      break;
    case KycStatus.Activated:
      color = BadgeStyle.LightSuccess;
      break;
    case KycStatus.Deactivated:
      color = BadgeStyle.Light;
      break;
    case KycStatus.Locked:
      color = BadgeStyle.LightWarning;
      break;
    case KycStatus.SubmitToApproval:
      color = BadgeStyle.LightPrimary;
      break;
    case KycStatus.Disapproved:
      color = BadgeStyle.LightDanger;
      break;
    case KycStatus.Archived:
      color = BadgeStyle.Light;
      break;

    default:
      // eslint-disable-next-line no-case-declarations
      const n: never = currentStatus;
      console.log(n);
      break;
  }
  return {
    color,
  };
};

/**
 * @description: KYC展示状态badge
 * @param {NumberOrString} status
 * @param {CommonLanguageType} language
 * @return {*}
 */
export const getKycDisplayStatus = (status: NumberOrString) => {
  let color = "";
  const currentStatus = status as KycDisplayStatus;
  switch (currentStatus) {
    case KycDisplayStatus.Registered:
      color = BadgeStyle.Light;
      break;
    case KycDisplayStatus.Validated:
      color = BadgeStyle.LightSuccess;
      break;
    case KycDisplayStatus.Rejected:
      color = BadgeStyle.LightDanger;
      break;
    case KycDisplayStatus.Expired:
      color = BadgeStyle.LightWarning;
      break;
    default:
      // eslint-disable-next-line no-case-declarations
      const n: never = currentStatus;
      console.log(n);
      break;
  }
  return {
    color,
  };
};

/**
 * @description: 结算主体状态badge
 * @param {NumberOrString} status
 * @param {CommonLanguageType} language
 * @return {*}
 */
export const getEntityStatus = (status: NumberOrString) => {
  let color = "";
  const currentStatus = status as entityStatus;
  switch (currentStatus) {
    case entityStatus.Activated:
      color = BadgeStyle.LightSuccess;
      break;
    case entityStatus.Deactivated:
      color = BadgeStyle.Light;
      break;
    case entityStatus.Unverified:
      color = BadgeStyle.LightWarning;
      break;

    default:
      // eslint-disable-next-line no-case-declarations
      const n: never = currentStatus;
      console.log(n);
      break;
  }
  return {
    color,
  };
};

/**
 * @description: 结算主体服务状态Badge
 * @param {NumberOrString} status
 * @param {CommonLanguageType} language
 * @return {*}
 */
export const getEntityServiceStatus = (
  status: NumberOrString,
  language: CommonLanguageType
) => {
  let icon = "",
    title = "";
  const currentStatus = Number(status) as EntityServiceStatus;
  switch (currentStatus) {
    case EntityServiceStatus.PurchaseItems:
      icon = "media/icons/duotune/ecommerce/ecm001.svg";
      title = language("kyc.serviceStatus.purchaseItems");
      break;
    case EntityServiceStatus.SalesItems:
      icon = "media/icons/duotune/ecommerce/ecm003.svg";
      title = language("kyc.serviceStatus.salesItems");
      break;
    case EntityServiceStatus.LuxuryFulfillment:
      icon = "media/icons/duotune/ecommerce/ecm007.svg";
      title = language("kyc.serviceStatus.luxuryFulfillment");
      break;
    case EntityServiceStatus.TaxVatSettlement:
      icon = "media/icons/duotune/finance/fin011.svg";
      title = language("kyc.serviceStatus.taxVatSettlement");
      break;
    case EntityServiceStatus.Compliance:
      icon = "media/icons/duotune/general/gen070.svg";
      title = language("kyc.serviceStatus.taxVatSettlement");
      break;
    default:
      // eslint-disable-next-line no-case-declarations
      const n = currentStatus;
      console.log(n);
      break;
  }
  return {
    icon,
    title,
  };
};

/**
 * @description: KYC模块共用配置
 * @return {KycCommonConfig} params
 */
export const getKycCommonTypeParams = computed(() => {
  const route = useRoute();
  const params: KycCommonConfig = {
    entityType: "",
    applicationScenarios: "",
    getContactViewApi: undefined,
    getContactStateAddressApi: undefined,
    getContactEditableApi: undefined,
    addContactApi: undefined,
    updateContactApi: undefined,
    updateContactIdentificationApi: undefined,
    updateContactAdditionalApi: undefined,
    deleteContactApi: undefined,
    getRegistrationViewApi: undefined,
    getRegistrationStateAddressApi: undefined,
    getRegistrationEditableApi: undefined,
    addRegistrationApi: undefined,
    updateRegistrationApi: undefined,
    updateRegistrationIdentificationApi: undefined,
    updateRegistrationAdditionalApi: undefined,
    deleteRegistrationApi: undefined,
  };

  // 以不同模块区分的配置：
  if (route.path.includes("kyc-verification")) {
    params.applicationScenarios = ApplicationScenarios.Validations;
  } else if (route.path.includes("kyc-confirmation")) {
    params.applicationScenarios = ApplicationScenarios.Confirmations;
  } else if (
    route.path.includes("settlement-entity") &&
    route.path.includes("/information")
  ) {
    params.applicationScenarios = ApplicationScenarios.Production;
  } else if (
    route.path.includes("settlement-entity") &&
    route.path.includes("/historical-information")
  ) {
    params.applicationScenarios = ApplicationScenarios.Historical;
  }

  // 以自然人或法人身份区分的配置：
  if (route.path.includes("legal-entity")) {
    params.entityType = KycType.Juridical;
    params.getContactViewApi = ApiKyc.getJuridicalContactView;
    params.getContactStateAddressApi = ApiKyc.getJuridicalContactStateAddress;
    params.getContactEditableApi = ApiKyc.getJuridicalContactEditable;
    params.addContactApi = ApiKyc.addJuridicalContact;
    params.updateContactApi = ApiKyc.updateJuridicalContact;
    params.updateContactIdentificationApi =
      ApiKyc.updateJuridicalContactIdentification;
    params.updateContactAdditionalApi = ApiKyc.updateJuridicalContactAdditional;
    params.deleteContactApi = ApiKyc.deleteJuridicalContact;
    params.getRegistrationViewApi = ApiKyc.getJuridicalRegistrationStateAddress;
    params.getRegistrationStateAddressApi =
      ApiKyc.getJuridicalRegistrationStateAddress;
    params.getRegistrationEditableApi = ApiKyc.getJuridicalRegistrationEditable;
    params.addRegistrationApi = ApiKyc.addJuridicalRegistration;
    params.updateRegistrationApi = ApiKyc.updateJuridicalRegistration;
    params.updateRegistrationIdentificationApi =
      ApiKyc.updateJuridicalRegistrationIdentification;
    params.updateRegistrationAdditionalApi =
      ApiKyc.updateJuridicalRegistrationAdditional;
    params.deleteRegistrationApi = ApiKyc.deleteJuridicalRegistration;
  } else if (route.path.includes("natural-person")) {
    params.entityType = KycType.Natural;
    params.getContactViewApi = ApiKyc.getNaturalContactView;
    params.getContactStateAddressApi = ApiKyc.getNaturalContactStateAddress;
    params.getContactEditableApi = ApiKyc.getNaturalContactEditable;
    params.addContactApi = ApiKyc.addNaturalContact;
    params.updateContactApi = ApiKyc.updateNaturalContact;
    params.updateContactIdentificationApi =
      ApiKyc.updateNaturalContactIdentification;
    params.updateContactAdditionalApi = ApiKyc.updateNaturalContactAdditional;
    params.deleteContactApi = ApiKyc.deleteNaturalContact;
    params.getRegistrationViewApi = ApiKyc.getNaturalRegistrationView;
    params.getRegistrationStateAddressApi =
      ApiKyc.getNaturalRegistrationStateAddress;
    params.getRegistrationEditableApi = ApiKyc.getNaturalRegistrationEditable;
    params.addRegistrationApi = ApiKyc.addNaturalRegistration;
    params.updateRegistrationApi = ApiKyc.updateNaturalRegistration;
    params.updateRegistrationIdentificationApi =
      ApiKyc.updateNaturalRegistrationIdentification;
    params.updateRegistrationAdditionalApi =
      ApiKyc.updateNaturalRegistrationAdditional;
    params.deleteRegistrationApi = ApiKyc.deleteNaturalRegistration;
  }
  return params;
});

/**
 * @description: KYC验证模块配置
 * @return {KycVerificationConfig} params
 */
export const getKycVerificationTypeParams = computed(() => {
  const route = useRoute();
  const routeName = route.name;
  const params: KycVerificationConfig = {
    routerUrl: "",
  };
  switch (routeName) {
    case "kyc-verification-legal-entity":
    case "kyc-verification-legal-entity-add":
    case "kyc-verification-legal-entity-information":
    case "kyc-verification-legal-entity-history-logs":
      params.routerUrl = "/kyc-verification/legal-entity";
      break;
    case "kyc-verification-natural-person":
    case "kyc-verification-natural-person-add":
    case "kyc-verification-natural-person-information":
    case "kyc-verification-natural-person-history-logs":
      params.routerUrl = "/kyc-verification/natural-person";
      break;
  }
  return params;
});

/**
 * @description: KYC确认模块配置
 * @return {KycConfirmationConfig} params
 */
export const getKycConfirmationTypeParams = computed(() => {
  const route = useRoute();
  //const { t } = useI18n();
  const routeName = route.name;
  const params: KycConfirmationConfig = {
    routerUrl: "",
  };
  switch (routeName) {
    case "kyc-confirmation-legal-entity":
    case "kyc-confirmation-legal-entity-information":
      params.routerUrl = "/kyc-confirmation/legal-entity";
      break;
    case "kyc-confirmation-natural-person":
    case "kyc-confirmation-natural-person-information":
      params.routerUrl = "/kyc-confirmation/natural-person";
      break;
  }
  return params;
});

/**
 * @description: 结算主体模块配置
 * @return {SettlementEntityConfig} params
 */
export const getSettlementEntityTypeParams = computed(() => {
  const route = useRoute();
  const routeName = route.name;
  const params: SettlementEntityConfig = {
    routerUrl: "",
    getSettlementEntityListApi: undefined,
  };
  switch (routeName) {
    case "settlement-entity-legal-entity":
    case "settlement-entity-legal-entity-information":
    case "settlement-entity-legal-entity-historical-information":
      params.routerUrl = "/settlement-entity/legal-entity";
      params.getSettlementEntityListApi =
        ApiKyc.getJuridicalSettlementEntityList;
      break;
    case "settlement-entity-natural-person":
    case "settlement-entity-natural-person-information":
    case "settlement-entity-natural-person-historical-information":
      params.routerUrl = "/settlement-entity/natural-person";
      params.getSettlementEntityListApi = ApiKyc.getNaturalSettlementEntityList;
      break;
  }
  return params;
});

/**
 * @description: 找出数组中重复的元素
 * @return {Array} duplicates
 */
export const findDuplicateValues = (arr, key) => {
  const valueCount = new Map();
  const duplicates = new Set();

  arr.forEach((obj) => {
    const keyValue = obj[key];
    if (valueCount.has(keyValue)) {
      valueCount.set(keyValue, valueCount.get(keyValue) + 1);
      duplicates.add(keyValue);
    } else {
      valueCount.set(keyValue, 1);
    }
  });
  return Array.from(duplicates);
};
