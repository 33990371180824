/*
 * @Descripttion:
 * @Author: xiang.gao
 * @Date: 2022-05-30 18:50:37
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-06-12 16:22:17
 */
export enum Projetcs {
  Base,
  Merchant,
  Distribution,
  Fulfillment,
  Settlement,
  PIM,
  CDN,
}

export type ProjetcsAll = keyof typeof Projetcs;

export type NumberOrString = number | string;

export type CommonIdType = NumberOrString | string[];

export type CommonDateType = string | null;

export type CommonLanguageType = (key: string | number) => string;

export type CommonHtmlType = HTMLElement | null;

export type CommonFormType = HTMLFormElement | null;

export type CommonButtonType = HTMLButtonElement | null;

export type PartialAllNo<T> = {
  [K in keyof T]?: T[K];
};

export type PartialAllReadonly<T> = {
  readonly [K in keyof T]: T[K];
};

export type RemoveField<T, U> = {
  [K in keyof T as Exclude<K, U>]: T[K];
};

export enum CommonSwtich {
  InActive,
  Active,
}

export enum CommonSwitch {
  InActive,
  Active,
}

export enum CommonDropdownSwtich {
  No = 1,
  Yes,
}

export enum CommonRequiredSwtich {
  Requried = 1,
  NotRequried,
}

export enum BadgeStyle {
  White = "badge-white",
  Primary = "badge-primary",
  Light = "badge-light",
  Secondary = "badge-secondary",
  Success = "badge-success",
  Info = "badge-info",
  Warning = "badge-warning",
  Danger = "badge-danger",
  Dark = "badge-dark",
  LightPrimary = "badge-light-primary",
  LightSuccess = "badge-light-success",
  LightInfo = "badge-light-info",
  LightInfoCustom = "light-info-custom",
  LightWarning = "badge-light-warning",
  LightDanger = "badge-light-danger",
  LightSecondary = "badge-light-secondary",
  LightDark = "badge-light-dark",
}

export enum TextStyle {
  White = "text-white",
  Primary = "text-primary",
  Light = "text-light",
  Secondary = "text-secondary",
  Success = "text-success",
  Info = "text-info",
  Warning = "text-warning",
  Danger = "text-danger",
  Dark = "text-dark",
  LightPrimary = "text-light-primary",
  LightSuccess = "text-light-success",
  LightInfo = "text-light-info",
  LightWarning = "text-light-warning",
  LightDanger = "text-light-danger",
  LightDark = "text-light-dark",
}

export enum SvgStyle {
  White = "svg-icon-white",
  Muted = "svg-icon-muted",
  Primary = "svg-icon-primary",
  Light = "svg-icon-light",
  Secondary = "svg-icon-secondary",
  Success = "svg-icon-success",
  Info = "svg-icon-info",
  Warning = "svg-icon-warning",
  Danger = "svg-icon-danger",
  Dark = "svg-icon-dark",
  LightPrimary = "svg-icon-light-primary",
  LightSuccess = "svg-icon-light-success",
  LightInfo = "svg-icon-light-info",
  LightWarning = "svg-icon-light-warning",
  LightDanger = "svg-icon-light-danger",
  LightDark = "svg-icon-light-dark",
}

export enum BgStyle {
  White = "bg-white",
  Primary = "bg-primary",
  Light = "bg-light",
  Secondary = "bg-secondary",
  Success = "bg-success",
  Info = "bg-info",
  Warning = "bg-warning",
  Danger = "bg-danger",
  Dark = "bg-dark",
  LightPrimary = "bg-light-primary",
  LightSuccess = "bg-light-success",
  LightInfo = "bg-light-info",
  LightWarning = "bg-light-warning",
  LightDanger = "bg-light-danger",
  LightDark = "bg-light-dark",
}
