/*
 * @Author: Nick juntong.wu@travel-easy.com
 * @Date: 2024-08-22 16:19:52
 * @LastEditors: Nick juntong.wu@travel-easy.com
 * @LastEditTime: 2024-08-29 21:51:17
 * @FilePath: \settlement-frontend\src\core\api\kyc.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  // KYC验证列表
  getKycVerificationList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/kyc/validation-list",
      params
    );
  },
  // 新增法人KYC验证
  addJuridicalKycVerification(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/kyc/settlement-entity/juridical/create",
      params
    );
  },
  // 新增自然人KYC验证
  addNaturalKycVerification(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/kyc/settlement-entity/natural/create",
      params
    );
  },

  // 法人联系信息
  getJuridicalContactList(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/juridical/contact/list",
      params
    );
  },
  getJuridicalContactView(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/juridical/contact/view",
      params
    );
  },
  getJuridicalContactStateAddress(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/juridical/contact/state-address",
      params
    );
  },
  getJuridicalContactEditable(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/juridical/contact/editable",
      params
    );
  },
  addJuridicalContact(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/juridical/contact/create",
      params
    );
  },
  updateJuridicalContact(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/juridical/contact/update",
      params
    );
  },
  updateJuridicalContactIdentification(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/juridical/contact/update-identification",
      params
    );
  },
  updateJuridicalContactAdditional(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/juridical/contact/update-additional",
      params
    );
  },
  deleteJuridicalContact(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/juridical/contact/delete",
      params
    );
  },

  // 自然人联系信息
  getNaturalContactList(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/natural/contact/list",
      params
    );
  },
  getNaturalContactView(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/natural/contact/view",
      params
    );
  },
  getNaturalContactStateAddress(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/natural/contact/state-address",
      params
    );
  },
  getNaturalContactEditable(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/natural/contact/editable",
      params
    );
  },
  addNaturalContact(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/natural/contact/create",
      params
    );
  },
  updateNaturalContact(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/natural/contact/update",
      params
    );
  },
  updateNaturalContactIdentification(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/natural/contact/update-identification",
      params
    );
  },
  updateNaturalContactAdditional(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/natural/registration/update-additional",
      params
    );
  },
  deleteNaturalContact(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/natural/contact/delete",
      params
    );
  },

  // 法人注册信息
  getJuridicalRegistrationList(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/juridical/registration/list",
      params
    );
  },
  getJuridicalRegistrationView(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/juridical/registration/view",
      params
    );
  },
  getJuridicalRegistrationStateAddress(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/juridical/registration/state-address",
      params
    );
  },
  getJuridicalRegistrationEditable(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/juridical/registration/editable",
      params
    );
  },
  addJuridicalRegistration(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/juridical/registration/create",
      params
    );
  },
  updateJuridicalRegistration(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/juridical/registration/update",
      params
    );
  },
  updateJuridicalRegistrationIdentification(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/juridical/registration/update-identification",
      params
    );
  },
  updateJuridicalRegistrationAdditional(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/juridical/registration/update-additional",
      params
    );
  },
  deleteJuridicalRegistration(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/juridical/registration/delete",
      params
    );
  },
  getCompanyLegalFormData(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/data-source/company-legal-form",
      params
    );
  },

  // 自然人注册信息
  getNaturalRegistrationList(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/natural/registration/list",
      params
    );
  },
  getNaturalRegistrationView(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/natural/registration/view",
      params
    );
  },
  getNaturalRegistrationStateAddress(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/natural/registration/state-address",
      params
    );
  },
  getNaturalRegistrationEditable(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/natural/registration/editable",
      params
    );
  },
  addNaturalRegistration(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/natural/registration/create",
      params
    );
  },
  updateNaturalRegistration(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/natural/registration/update",
      params
    );
  },
  updateNaturalRegistrationIdentification(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/natural/registration/update-identification",
      params
    );
  },
  updateNaturalRegistrationAdditional(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/natural/registration/update-additional",
      params
    );
  },
  deleteNaturalRegistration(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/natural/registration/delete",
      params
    );
  },

  // 账单信息
  getBillingList(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/billing-address/list",
      params
    );
  },
  getBillingEditable(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/billing-address/editable",
      params
    );
  },
  addBilling(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/billing-address/create",
      params
    );
  },
  updateBilling(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/billing-address/update-identification",
      params
    );
  },
  deleteBilling(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/billing-address/delete",
      params
    );
  },

  // 仓库信息
  getWarehouseList(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/warehouse-address/list",
      params
    );
  },
  getWarehouseEditable(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/warehouse-address/editable",
      params
    );
  },
  addWarehouse(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/warehouse-address/create",
      params
    );
  },
  updateWarehouse(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/warehouse-address/update",
      params
    );
  },
  updateWarehouseIdentification(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/warehouse-address/update-identification",
      params
    );
  },
  updateWarehouseAdditional(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/warehouse-address/update-additional",
      params
    );
  },
  deleteWarehouse(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/warehouse-address/delete",
      params
    );
  },

  // 库存地址信息
  getInventoryList(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/inventory-address/list",
      params
    );
  },
  getInventoryEditable(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/inventory-address/editable",
      params
    );
  },
  addInventory(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/inventory-address/create",
      params
    );
  },
  updateInventory(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/inventory-address/update",
      params
    );
  },
  updateInventoryIdentification(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/inventory-address/update-identification",
      params
    );
  },
  updateInventoryAdditional(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/inventory-address/update-additional",
      params
    );
  },
  deleteInventory(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/inventory-address/delete",
      params
    );
  },

  // 财务信息
  getFinancialList(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/payment-institution-address/list",
      params
    );
  },
  getFinancialEditable(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/payment-institution-address/editable",
      params
    );
  },
  addFinancial(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/payment-institution-address/create",
      params
    );
  },
  updateFinancial(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/payment-institution-address/update",
      params
    );
  },
  updateFinancialIdentification(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/payment-institution-address/update-identification",
      params
    );
  },
  updateFinancialAdditional(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/payment-institution-address/update-additional",
      params
    );
  },
  deleteFinancial(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/payment-institution-address/delete",
      params
    );
  },

  // 税务信息
  getTaxList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/kyc/settlement-entity/tax-address/list",
      params
    );
  },
  getTaxEditable(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/tax-address/editable",
      params
    );
  },
  getTaxAuthorityData(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/data-source/local-tax-authority",
      params
    );
  },
  addTax(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/kyc/settlement-entity/tax-address/create",
      params
    );
  },
  updateTax(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/tax-address/update-identification",
      params
    );
  },
  deleteTax(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/kyc/settlement-entity/tax-address/delete",
      params
    );
  },

  // 提交审批
  submitToApproval(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/address/submit-to-approval",
      params
    );
  },

  // KYC确认列表
  getKycConfirmationList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/kyc/confirmation-list",
      params
    );
  },

  // 地址审批
  ApproveKycConfirmation(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/kyc/settlement-entity/address/approvals",
      params
    );
  },

  // 法人结算主体列表
  getJuridicalSettlementEntityList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/kyc/settlement-entity/juridical/list",
      params
    );
  },

  // 自然人结算主体列表
  getNaturalSettlementEntityList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/kyc/settlement-entity/natural/list",
      params
    );
  },

  // 结算主体overview
  getSettlementEntityOverview(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/kyc/settlement-entity/overview",
      params
    );
  },

  // 地址状态变更
  changeAddressStatus(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/kyc/settlement-entity/address/change-status",
      params
    );
  },

  // 结算主体状态变更
  changeSettlementEntityStatus(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/kyc/settlement-entity/change-status",
      params
    );
  },
};
