import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import {
  MBLayout,
  MBAuth,
  MBSignIn,
  MBError404,
  MBError500,
} from "magicbean-saas-common";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: MBLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      // documents
      {
        path: "/settlement-documents/invoice",
        name: "settlement-invoice",
        component: () => import("@/views/documents/invoice/Index.vue"),
      },

      {
        path: "/settlement-documents/report",
        name: "settlement-report",
        component: () => import("@/views/documents/report/Index.vue"),
      },
      // document-center
      {
        path: "/document-center/billing",
        name: "document-center-billing",
        component: () => import("@/views/document-center/billing/Index.vue"),
      },
      {
        path: "/document-center/billing-verification",
        name: "document-center-billing-verification",
        component: () =>
          import("@/views/document-center/billing-verification/Index.vue"),
      },
      {
        path: "/document-center/payment",
        name: "document-center-payment",
        component: () => import("@/views/document-center/payment/Index.vue"),
      },
      {
        path: "/document-center/payment-verification",
        name: "document-center-payment-verification",
        component: () =>
          import("@/views/document-center/payment-verification/Index.vue"),
      },
      // quick-settlement
      {
        path: "/quick-settlement",
        name: "quick-settlement",
        component: () => import("@/views/quick-settlement/Index.vue"),
      },
      {
        path: "/quick-settlement/:id/Information",
        name: "quick-settlement-information",
        component: () => import("@/views/quick-settlement/Information.vue"),
      },

      {
        path: "/api-integration/datev-balance",
        name: "api-integration-datev-balance",
        component: () =>
          import("@/views/api-integration/datev-balance/Index.vue"),
      },
      {
        path: "/api-integration/datev-balance-verification",
        name: "api-integration-datev-balance-verification",
        component: () =>
          import(
            "@/views/api-integration/datev-balance-verification/Index.vue"
          ),
      },
      // merchant-invoice
      {
        path: "/merchant-invoice",
        name: "merchant-invoice",
        component: () => import("@/views/merchant-invoice/Index.vue"),
      },
      {
        path: "/merchant-invoice-finance",
        name: "merchant-invoice-finance",
        component: () => import("@/views/merchant-invoice-finance/Index.vue"),
      },
      // merchant-credit-notes
      {
        path: "/merchant-credit-notes",
        name: "merchant-credit-notes",
        component: () => import("@/views/merchant-credit-notes/Index.vue"),
      },
      {
        path: "/merchant-credit-notes-finance",
        name: "merchant-credit-notes-finance",
        component: () =>
          import("@/views/merchant-credit-notes-finance/Index.vue"),
      },
      // transaction-report
      {
        path: "/transaction-report/finacial-report/ocps-ss-sales-receivables",
        name: "transaction-report-finacial-report-ocps-ss-sales-receivables",
        meta: {
          contentWidth: "fluid",
        },
        component: () =>
          import(
            "@/views/transaction-report/finacial-report/ocps-ss-sales-receivables/Index.vue"
          ),
      },
      {
        path: "/transaction-report/finacial-report/ocps-deposit-management",
        name: "transaction-report-finacial-report-ocps-deposit-management",
        meta: {
          contentWidth: "fluid",
        },
        component: () =>
          import(
            "@/views/transaction-report/finacial-report/ocps-deposit-management/Index.vue"
          ),
      },
      {
        path: "/transaction-report/finacial-report/ocps-e-commerce-tax-payables",
        name: "transaction-report-finacial-report-ocps-e-commerce-tax-payables",
        meta: {
          contentWidth: "fluid",
        },
        component: () =>
          import(
            "@/views/transaction-report/finacial-report/ocps-e-commerce-tax-payables/Index.vue"
          ),
      },
      {
        path: "/transaction-report/finacial-report/ocps-ds-rebates",
        name: "transaction-report-finacial-report-ocps-ds-rebates",
        meta: {
          contentWidth: "fluid",
        },
        component: () =>
          import(
            "@/views/transaction-report/finacial-report/ocps-ds-rebates/Index.vue"
          ),
      },
      {
        path: "/transaction-report/finacial-report/ocps-vat-receivables",
        name: "transaction-report-finacial-report-ocps-vat-receivables",
        meta: {
          contentWidth: "fluid",
        },
        component: () =>
          import(
            "@/views/transaction-report/finacial-report/ocps-vat-receivables/Index.vue"
          ),
      },
      // {
      //   path: "/transaction-report/finacial-report/ocps-merchant-purchasing-payables",
      //   name: "transaction-report-finacial-report-ocps-merchant-purchasing-payables",
      //   meta: {
      //     contentWidth: "fluid",
      //   },
      //   component: () =>
      //     import(
      //       "@/views/transaction-report/finacial-report/ocps-merchant-purchasing-payables/Index.vue"
      //     ),
      // },
      // settlement-cash
      {
        path: "/settlement-cash/internal-cash-pool",
        name: "settlement-cash-internal-cash-pool",
        meta: {
          contentWidth: "fluid",
        },
        component: () =>
          import("@/views/settlement-cash/internal-cash-pool/Index.vue"),
      },
      {
        path: "/settlement-cash/internal-bank-balance",
        name: "settlement-cash-internal-bank-balance",
        meta: {
          contentWidth: "fluid",
        },
        component: () =>
          import("@/views/settlement-cash/internal-bank-balance/Index.vue"),
      },
      /// settlement-entity
      {
        path: "/settlement-master-data/settlement-entity",
        name: "settlement-master-data-settlement-entity",
        component: () =>
          import("@/views/settlement-master-data/settlement-entity/Index.vue"),
      },
      {
        path: "/settlement-master-data/settlement-entity/:id/overview",
        name: "settlement-master-data-settlement-entity-overview",
        component: () =>
          import(
            "@/views/settlement-master-data/settlement-entity/Overview.vue"
          ),
      },
      {
        path: "/settlement-master-data/settlement-entity/:id/update",
        name: "settlement-master-data-settlement-entity-update",
        component: () =>
          import("@/views/settlement-master-data/settlement-entity/Update.vue"),
      },
      /// entity-relationships
      {
        path: "/entity-relationships-ssp/supplier",
        name: "entity-relationships-ssp-supplier",
        component: () =>
          import("@/views/entity-relationships-ssp/supplier/Index.vue"),
        meta: {
          contentWidth: "fluid",
        },
      },
      {
        path: "/entity-relationships-ssp/purchaser",
        name: "entity-relationships-ssp-purchaser",
        component: () =>
          import("@/views/entity-relationships-ssp/purchaser/Index.vue"),
        meta: {
          contentWidth: "fluid",
        },
      },
      {
        path: "/entity-relationships-ssp/clientele",
        name: "entity-relationships-ssp-clientele",
        component: () =>
          import("@/views/entity-relationships-ssp/clientele/Index.vue"),
        meta: {
          contentWidth: "fluid",
        },
      },
      {
        path: "/entity-relationships-ssp/payee",
        name: "entity-relationships-ssp-payee",
        component: () =>
          import("@/views/entity-relationships-ssp/payee/Index.vue"),
        meta: {
          contentWidth: "fluid",
        },
      },
      {
        path: "/entity-relationships-ssp/rebate",
        name: "entity-relationships-ssp-rebate",
        component: () =>
          import("@/views/entity-relationships-ssp/rebate/Index.vue"),
        meta: {
          contentWidth: "fluid",
        },
      },
      {
        path: "/entity-relationships-retailer/exporter",
        name: "entity-relationships-retailer-exporter",
        component: () =>
          import("@/views/entity-relationships-retailer/exporter/Index.vue"),
        meta: {
          contentWidth: "fluid",
        },
      },
      {
        path: "/entity-relationships-retailer/tax-agent",
        name: "entity-relationships-retailer-tax-agent",
        component: () =>
          import("@/views/entity-relationships-retailer/tax-agent/Index.vue"),
        meta: {
          contentWidth: "fluid",
        },
      },
      {
        path: "/entity-relationships-retailer/purchaser",
        name: "entity-relationships-retailer-purchaser",
        component: () =>
          import("@/views/entity-relationships-retailer/purchaser/Index.vue"),
        meta: {
          contentWidth: "fluid",
        },
      },

      // kyc-verification
      {
        path: "/kyc-verification/legal-entity",
        name: "kyc-verification-legal-entity",
        component: () =>
          import("@/views/kyc-verification/legal-entity/Index.vue"),
        meta: {
          contentWidth: "fluid",
        },
      },
      {
        path: "/kyc-verification/legal-entity/:id",
        name: "kyc-verification-legal-entity-detail",
        component: () =>
          import("@/views/kyc-verification/legal-entity/Detail.vue"),
        children: [
          {
            path: "add",
            name: "kyc-verification-legal-entity-add",
            component: () =>
              import(
                "@/views/kyc-verification/legal-entity/AddKYCVerification.vue"
              ),
          },
          {
            path: "information",
            name: "kyc-verification-legal-entity-information",
            component: () =>
              import("@/views/kyc-verification/legal-entity/Information.vue"),
          },
        ],
      },
      {
        path: "/kyc-verification/natural-person",
        name: "kyc-verification-natural-person",
        component: () =>
          import("@/views/kyc-verification/natural-person/Index.vue"),
        meta: {
          contentWidth: "fluid",
        },
      },
      {
        path: "/kyc-verification/natural-person/:id",
        name: "kyc-verification-natural-person-detail",
        component: () =>
          import("@/views/kyc-verification/natural-person/Detail.vue"),
        children: [
          {
            path: "add",
            name: "kyc-verification-natural-person-add",
            component: () =>
              import(
                "@/views/kyc-verification/natural-person/AddKYCVerification.vue"
              ),
          },
          {
            path: "information",
            name: "kyc-verification-natural-person-information",
            component: () =>
              import("@/views/kyc-verification/natural-person/Information.vue"),
          },
        ],
      },

      // kyc-confirmation
      {
        path: "/kyc-confirmation/legal-entity",
        name: "kyc-confirmation-legal-entity",
        component: () =>
          import("@/views/kyc-confirmation/legal-entity/Index.vue"),
        meta: {
          contentWidth: "fluid",
        },
      },
      {
        path: "/kyc-confirmation/legal-entity/:id",
        name: "kyc-confirmation-legal-entity-detail",
        component: () =>
          import("@/views/kyc-confirmation/legal-entity/Detail.vue"),
        children: [
          {
            path: "information",
            name: "kyc-confirmation-legal-entity-information",
            component: () =>
              import("@/views/kyc-confirmation/legal-entity/Information.vue"),
          },
        ],
      },
      {
        path: "/kyc-confirmation/natural-person",
        name: "kyc-confirmation-natural-person",
        component: () =>
          import("@/views/kyc-confirmation/natural-person/Index.vue"),
        meta: {
          contentWidth: "fluid",
        },
      },
      {
        path: "/kyc-confirmation/natural-person/:id",
        name: "kyc-confirmation-natural-person-detail",
        component: () =>
          import("@/views/kyc-confirmation/natural-person/Detail.vue"),
        children: [
          {
            path: "information",
            name: "kyc-confirmation-natural-person-information",
            component: () =>
              import("@/views/kyc-confirmation/natural-person/Information.vue"),
          },
        ],
      },

      // settlement-entity
      {
        path: "/settlement-entity/legal-entity",
        name: "settlement-entity-legal-entity",
        component: () =>
          import("@/views/settlement-entity/legal-entity/Index.vue"),
        meta: {
          contentWidth: "fluid",
        },
      },
      {
        path: "/settlement-entity/legal-entity/:id",
        name: "settlement-entity-legal-entity-detail",
        component: () =>
          import("@/views/settlement-entity/legal-entity/Detail.vue"),
        meta: {
          rederectToHome: true,
        },
        children: [
          {
            path: "information",
            name: "settlement-entity-legal-entity-information",
            component: () =>
              import("@/views/settlement-entity/legal-entity/Information.vue"),
          },
          {
            path: "historical-information",
            name: "settlement-entity-legal-entity-historical-information",
            component: () =>
              import(
                "@/views/settlement-entity/legal-entity/HistoricalInformation.vue"
              ),
          },
        ],
      },
      {
        path: "/settlement-entity/natural-person",
        name: "settlement-entity-natural-person",
        component: () =>
          import("@/views/settlement-entity/natural-person/Index.vue"),
        meta: {
          contentWidth: "fluid",
        },
      },
      {
        path: "/settlement-entity/natural-person/:id",
        name: "settlement-entity-natural-person-detail",
        component: () =>
          import("@/views/settlement-entity/natural-person/Detail.vue"),
        meta: {
          rederectToHome: true,
        },
        children: [
          {
            path: "information",
            name: "settlement-entity-natural-person-information",
            component: () =>
              import(
                "@/views/settlement-entity/natural-person/Information.vue"
              ),
          },
          {
            path: "historical-information",
            name: "settlement-entity-natural-person-historical-information",
            component: () =>
              import(
                "@/views/settlement-entity/natural-person/HistoricalInformation.vue"
              ),
          },
        ],
      },

      /*// Settlement Entity
      {
        path: "/settlement-entity/retailer",
        name: "settlement-entity-retailer",
        component: () => import("@/views/settlement-entity/retailer/Index.vue"),
        meta: {
          contentWidth: "fluid",
        },
      },
      {
        path: "/settlement-entity/retailer/:id",
        name: "settlement-entity-retailer-detail",
        component: () =>
          import("@/views/settlement-entity/retailer/Detail.vue"),
        meta: {
          rederectToHome: true,
        },
        children: [
          {
            path: "information",
            name: "settlement-entity-retailer-information",
            component: () =>
              import("@/views/settlement-entity/retailer/Information.vue"),
          },
          {
            path: "attachment",
            name: "settlement-entity-retailer-attachment",
            component: () =>
              import("@/views/settlement-entity/retailer/Attachment.vue"),
          },
          {
            path: "services",
            name: "settlement-entity-retailer-services",
            component: () =>
              import("@/views/settlement-entity/retailer/Services.vue"),
          },
        ],
      },
      {
        path: "/settlement-entity/purchaser",
        name: "settlement-entity-purchaser",
        component: () =>
          import("@/views/settlement-entity/purchaser/Index.vue"),
        meta: {
          contentWidth: "fluid",
        },
      },
      {
        path: "/settlement-entity/purchaser/:id",
        name: "settlement-entity-purchaser-detail",
        component: () =>
          import("@/views/settlement-entity/purchaser/Detail.vue"),
        meta: {
          rederectToHome: true,
        },
        children: [
          {
            path: "information",
            name: "settlement-entity-purchaser-information",
            component: () =>
              import("@/views/settlement-entity/purchaser/Information.vue"),
          },
          {
            path: "attachment",
            name: "settlement-entity-purchaser-attachment",
            component: () =>
              import("@/views/settlement-entity/purchaser/Attachment.vue"),
          },
          {
            path: "services",
            name: "settlement-entity-purchaser-services",
            component: () =>
              import("@/views/settlement-entity/purchaser/Services.vue"),
          },
        ],
      },
      {
        path: "/settlement-entity/buyer",
        name: "settlement-entity-buyer",
        component: () => import("@/views/settlement-entity/buyer/Index.vue"),
        meta: {
          contentWidth: "fluid",
        },
      },
      {
        path: "/settlement-entity/buyer/:id",
        name: "settlement-entity-buyer-detail",
        component: () => import("@/views/settlement-entity/buyer/Detail.vue"),
        meta: {
          rederectToHome: true,
        },
        children: [
          {
            path: "information",
            name: "settlement-entity-buyer-information",
            component: () =>
              import("@/views/settlement-entity/buyer/Information.vue"),
          },
          {
            path: "attachment",
            name: "settlement-entity-buyer-attachment",
            component: () =>
              import("@/views/settlement-entity/buyer/Attachment.vue"),
          },
          {
            path: "services",
            name: "settlement-entity-buyer-services",
            component: () =>
              import("@/views/settlement-entity/buyer/Services.vue"),
          },
        ],
      },
      {
        path: "/settlement-entity/consumer",
        name: "settlement-entity-consumer",
        component: () => import("@/views/settlement-entity/consumer/Index.vue"),
        meta: {
          contentWidth: "fluid",
        },
      },
      {
        path: "/settlement-entity/consumer/:id",
        name: "settlement-entity-consumer-detail",
        component: () =>
          import("@/views/settlement-entity/consumer/Detail.vue"),
        meta: {
          rederectToHome: true,
        },
        children: [
          {
            path: "information",
            name: "settlement-entity-consumer-information",
            component: () =>
              import("@/views/settlement-entity/consumer/Information.vue"),
          },
          {
            path: "attachment",
            name: "settlement-entity-consumer-attachment",
            component: () =>
              import("@/views/settlement-entity/consumer/Attachment.vue"),
          },
          {
            path: "services",
            name: "settlement-entity-consumer-services",
            component: () =>
              import("@/views/settlement-entity/consumer/Services.vue"),
          },
        ],
      },
      // service-management
      {
        path: "/service-management/financial-tax-service",
        name: "service-management-financial-tax-service",
        component: () =>
          import("@/views/service-management/financial-tax-service/Index.vue"),
        meta: {
          contentWidth: "fluid",
        },
      },
      {
        path: "/service-management/financial-tax-service/:id/overview",
        name: "service-management-financial-tax-service-overview",
        component: () =>
          import(
            "@/views/service-management/financial-tax-service/Overview.vue"
          ),
        meta: {
          contentWidth: "fluid",
          rederectToHome: true,
        },
      },
      {
        path: "/service-management/financial-tax-service/add",
        name: "service-management-financial-tax-service-overview-add",
        component: () =>
          import(
            "@/views/service-management/financial-tax-service/OverviewAdd.vue"
          ),
        meta: {
          contentWidth: "fluid",
          rederectToHome: true,
        },
      },
      {
        path: "/service-management/financial-tax-service/:id/overview-edit",
        name: "service-management-financial-tax-service-overview-edit",
        component: () =>
          import(
            "@/views/service-management/financial-tax-service/OverviewEdit.vue"
          ),
        meta: {
          contentWidth: "fluid",
          rederectToHome: true,
        },
      },
      {
        path: "/service-management/luxury-fulfillment-service",
        name: "service-management-luxury-fulfillment-service",
        component: () =>
          import(
            "@/views/service-management/luxury-fulfillment-service/Index.vue"
          ),
        meta: {
          contentWidth: "fluid",
        },
      },
      {
        path: "/service-management/luxury-fulfillment-service/add",
        name: "service-management-luxury-fulfillment-service-overview-add",
        component: () =>
          import(
            "@/views/service-management/luxury-fulfillment-service/OverviewAdd.vue"
          ),
        meta: {
          contentWidth: "fluid",
          rederectToHome: true,
        },
      },
      {
        path: "/service-management/luxury-fulfillment-service/:id/overview",
        name: "service-management-luxury-fulfillment-service-overview",
        component: () =>
          import(
            "@/views/service-management/luxury-fulfillment-service/Overview.vue"
          ),
        meta: {
          contentWidth: "fluid",
          rederectToHome: true,
        },
      },
      {
        path: "/service-management/luxury-fulfillment-service/:id/overview-edit",
        name: "service-management-luxury-fulfillment-service-overview-edit",
        component: () =>
          import(
            "@/views/service-management/luxury-fulfillment-service/OverviewEdit.vue"
          ),
        meta: {
          contentWidth: "fluid",
          rederectToHome: true,
        },
      },
      // kyc-verification
      {
        path: "/kyc-verification/legal-entity",
        name: "kyc-verification-legal-entity",
        component: () =>
          import("@/views/kyc-verification/legal-entity/Index.vue"),
        meta: {
          contentWidth: "fluid",
        },
      },
      {
        path: "/kyc-verification/legal-entity/:id",
        name: "kyc-verification-legal-entity-detail",
        component: () =>
          import("@/views/kyc-verification/legal-entity/Detail.vue"),
        children: [
          {
            path: "add",
            name: "kyc-verification-legal-entity-add",
            component: () =>
              import(
                "@/views/kyc-verification/legal-entity/AddKYCVerification.vue"
              ),
          },
          {
            path: "information",
            name: "kyc-verification-legal-entity-information",
            component: () =>
              import("@/views/kyc-verification/legal-entity/Information.vue"),
          },
          {
            path: "history-logs",
            name: "kyc-verification-legal-entity-history-logs",
            component: () =>
              import("@/views/kyc-verification/legal-entity/HistoryLogs.vue"),
          },
        ],
      },
      {
        path: "/kyc-verification/natural-person",
        name: "kyc-verification-natural-person",
        component: () =>
          import("@/views/kyc-verification/natural-person/Index.vue"),
        meta: {
          contentWidth: "fluid",
        },
      },
      {
        path: "/kyc-verification/natural-person/:id",
        name: "kyc-verification-natural-person-detail",
        component: () =>
          import("@/views/kyc-verification/natural-person/Detail.vue"),
        children: [
          {
            path: "add",
            name: "kyc-verification-natural-person-add",
            component: () =>
              import(
                "@/views/kyc-verification/natural-person/AddKYCVerification.vue"
              ),
          },
          {
            path: "information",
            name: "kyc-verification-natural-person-information",
            component: () =>
              import("@/views/kyc-verification/natural-person/Information.vue"),
          },
          {
            path: "history-logs",
            name: "kyc-verification-natural-person-history-logs",
            component: () =>
              import("@/views/kyc-verification/natural-person/HistoryLogs.vue"),
          },
        ],
      },
      // kyc-confirmation
      {
        path: "/kyc-confirmation/retailer",
        name: "kyc-confirmation-retailer",
        component: () => import("@/views/kyc-confirmation/retailer/Index.vue"),
        meta: {
          contentWidth: "fluid",
        },
      },
      {
        path: "/kyc-confirmation/retailer/:id",
        name: "kyc-confirmation-retailer-detail",
        component: () => import("@/views/kyc-confirmation/retailer/Detail.vue"),
        children: [
          {
            path: "information",
            name: "kyc-confirmation-retailer-information",
            component: () =>
              import("@/views/kyc-confirmation/retailer/Information.vue"),
          },
        ],
      },
      {
        path: "/kyc-confirmation/purchaser",
        name: "kyc-confirmation-purchaser",
        component: () => import("@/views/kyc-confirmation/purchaser/Index.vue"),
        meta: {
          contentWidth: "fluid",
        },
      },
      {
        path: "/kyc-confirmation/purchaser/:id",
        name: "kyc-confirmation-purchaser-detail",
        component: () =>
          import("@/views/kyc-confirmation/purchaser/Detail.vue"),
        children: [
          {
            path: "information",
            name: "kyc-confirmation-purchaser-information",
            component: () =>
              import("@/views/kyc-confirmation/purchaser/Information.vue"),
          },
        ],
      },
      {
        path: "/kyc-confirmation/buyer",
        name: "kyc-confirmation-buyer",
        component: () => import("@/views/kyc-confirmation/buyer/Index.vue"),
        meta: {
          contentWidth: "fluid",
        },
      },
      {
        path: "/kyc-confirmation/buyer/:id",
        name: "kyc-confirmation-buyer-detail",
        component: () => import("@/views/kyc-confirmation/buyer/Detail.vue"),
        children: [
          {
            path: "information",
            name: "kyc-confirmation-buyer-information",
            component: () =>
              import("@/views/kyc-confirmation/buyer/Information.vue"),
          },
        ],
      },
      {
        path: "/kyc-confirmation/consumer",
        name: "kyc-confirmation-consumer",
        component: () => import("@/views/kyc-confirmation/consumer/Index.vue"),
        meta: {
          contentWidth: "fluid",
        },
      },
      {
        path: "/kyc-confirmation/consumer/:id",
        name: "kyc-confirmation-consumer-detail",
        component: () => import("@/views/kyc-confirmation/consumer/Detail.vue"),
        children: [
          {
            path: "information",
            name: "kyc-confirmation-consumer-information",
            component: () =>
              import("@/views/kyc-confirmation/consumer/Information.vue"),
          },
        ],
      },*/
      //Refund Processing
      {
        path: "/refund-processing",
        name: "refund-processing",
        component: () => import("@/views/refund-processing/Index.vue"),
      },
      {
        path: "/refund-processing/:id/refund-order-no/:refundOrderNo",
        name: "refund-processing-information",
        component: () => import("@/views/refund-processing/Information.vue"),
      },
    ],
  },
  {
    path: "/",
    component: MBAuth,
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: MBSignIn,
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: MBError404,
  },
  {
    path: "/500",
    name: "500",
    component: MBError500,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  if (to.meta.contentWidth == "fluid") {
    store.commit(Mutations.OVERRIDE_PAGE_LAYOUT_CONFIG, {
      content: {
        width: "fluid",
      },
    });
  }

  if (to.name !== "sign-in") {
    store.dispatch(Actions.VERIFY_AUTH);
    store.dispatch(Actions.GET_MENU_ITEMS, to.path);
    next();
  } else {
    next();
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
